import React, { useState, forwardRef} from 'react';
import {Link} from "react-router-dom";
import AppContext from "./../../app-context"
import closeOnClickOutside from './../core/close-on-click-outside'

import {PUBLIC_URL} from './../../const'


const MenuUserProfile = forwardRef(({ open, setOpen }, ref) => {
    var openClass = ""
    if (open){
        openClass = "show"
    }
    return (
        <>
            <a
                className="nav-link pe-0"
                href="javascript:void(0)"
                onClick={() => {
                    setOpen(!open)
                }}
                id="drop1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className="d-flex align-items-center">
                    <div className="user-profile-img">
                        <img
                            src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`}
                            className="rounded-circle"
                            width="35"
                            height="35"
                            alt=""
                        />
                    </div>
                </div>
            </a>
            <div className={`dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up ${openClass}`} aria-labelledby="drop1" data-bs-popper="static" ref={ref}>
                <div className="profile-dropdown position-relative" data-simplebar="init">
                    <div className="simplebar-wrapper" style={{margin: "0px"}}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                <div className="simplebar-content-wrapper" tabIndex="0"
                                     role="region" aria-label="scrollable content"
                                     style={{height: "auto", overflow: "hidden"}}>
                                    <div className="simplebar-content" style={{padding: "0px"}}>
                                        <div className="py-3 px-7 pb-0">
                                            <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                                        </div>
                                        <div
                                            className="d-flex align-items-center py-9 border-bottom" style={{paddingLeft: "20px"}}>
                                            <img src={`${PUBLIC_URL}/assets/images/profile/user-1.jpg`}
                                                 className="rounded-circle" width="80" height="80"
                                                 alt="" />
                                            <div className="ms-3">
                                                <h5 className="mb-1 fs-3">{AppContext.user.name}</h5>
                                                <span className="mb-1 d-block">{AppContext.user.role}</span>
                                                <p className="mb-0 d-flex align-items-center gap-2"><i className="ti ti-mail fs-4"></i> {AppContext.user.email}</p>
                                            </div>
                                        </div>
                                        <div className="message-body">
                                            <Link to="/my-profile/1" className="py-8 px-7 mt-8 d-flex align-items-center" onClick={() => {setOpen(false)}}>
                                                <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                  <img src={`${PUBLIC_URL}/assets/images/svgs/icon-account.svg`} alt="" width="24" height="24" />
                                                </span>

                                                <div className="w-75 d-inline-block v-middle ps-3">
                                                    <h6 className="mb-1 fs-3 fw-semibold lh-base">My Profile</h6>
                                                    <span className="fs-2 d-block text-body-secondary">Account Settings</span>
                                                </div>
                                            </Link>
                                            <Link to="/messages" className="py-8 px-7 mt-8 d-flex align-items-center" onClick={() => {setOpen(false)}}>
                                                <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                  <img src={`${PUBLIC_URL}/assets/images/svgs/icon-inbox.svg`} alt="" width="24" height="24" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle ps-3">
                                                    <h6 className="mb-1 fs-3 fw-semibold lh-base">My Inbox</h6>
                                                    <span className="fs-2 d-block text-body-secondary">Messages</span>
                                                </div>
                                            </Link>
                                            <Link to="/chat-requests" className="py-8 px-7 mt-8 d-flex align-items-center" onClick={() => {setOpen(false)}}>
                                                <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                  <img src={`${PUBLIC_URL}/assets/images/svgs/icon-tasks.svg`} alt="" width="24" height="24" />
                                                </span>

                                                <div className="w-75 d-inline-block v-middle ps-3">
                                                    <h6 className="mb-1 fs-3 fw-semibold lh-base">My Chats</h6>
                                                    <span className="fs-2 d-block text-body-secondary">Active Chats and new requests</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="d-grid py-4 px-7 pt-8">
                                            <div
                                                className="upgrade-plan bg-primary-subtle position-relative overflow-hidden rounded-4 p-4 mb-9">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h5 className="fs-4 mb-3 fw-semibold">{AppContext.tariff.name}</h5>
                                                        <Link to="/pricing-plans" className="btn btn-primary">
                                                            Upgrade
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="m-n4 unlimited-img">
                                                            <img
                                                                src={`${PUBLIC_URL}/assets/images/backgrounds/unlimited-bg.png`}
                                                                alt="" className="w-100" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="../main/authentication-login.html"
                                               className="btn btn-outline-primary">Log Out</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simplebar-placeholder"
                             style={{width: "360px", height: "603px"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal"
                         style={{visibility: "hidden"}}>
                        <div className="simplebar-scrollbar"
                             style={{width: "0px", display: "none"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={{visibility: "hidden"}}>
                        <div className="simplebar-scrollbar"
                             style={{height: "0px", display: "none"}}></div>
                    </div>
                </div>

            </div>
        </>
    )
});

export default closeOnClickOutside(MenuUserProfile)
