import React, { useState, forwardRef} from 'react';
import closeOnClickOutside from './../core/close-on-click-outside'
import {PUBLIC_URL} from './../../const'

const MenuApps = forwardRef(({ open, setOpen }, ref) => {
    var openClass = ""
    if (open){
        openClass = "show"
    }

    return (
        <>
            <a className="nav-link" href="javascript:void(0)" onClick={() => {setOpen(!open)}}>
                Apps<span className="mt-1"><i className="ti ti-chevron-down fs-3"></i></span>
            </a>
            <div className={`dropdown-menu dropdown-menu-nav dropdown-menu-animate-up py-0 ${openClass}`} aria-labelledby="drop1" data-bs-popper="static" style={{height: "200px"}}>
                <div className="profile-dropdown position-relative" data-simplebar="init">
                    <div className="simplebar-wrapper" style={{margin: "0px"}}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                                <div className="simplebar-content-wrapper" tabIndex="0"
                                     role="region" aria-label="scrollable content"
                                     style={{height: "auto", overflow: "hidden"}}>
                                    <div className="simplebar-content" style={{padding: "0px"}} ref={ref}>
                                        <div className="py-3 px-7 pb-0">
                                            <h5 className="mb-0 fs-5 fw-semibold">Our Applications</h5>
                                        </div>
                                        <div className="message-body">
                                            <a href="../main/page-user-profile.html" className="py-8 px-7 mt-8 d-flex align-items-center">
                                                <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                  <img src={`${PUBLIC_URL}/assets/images/svgs/icon-account.svg`} alt="" width="24" height="24" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle ps-3">
                                                    <h6 className="mb-1 fs-3 fw-semibold lh-base">Track My Time</h6>
                                                    <span className="fs-2 d-block text-body-secondary">Account Settings</span>
                                                </div>
                                            </a>
                                            <a href="../main/app-email.html" className="py-8 px-7 d-flex align-items-center">
                                                <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                  <img src={`${PUBLIC_URL}/assets/images/svgs/icon-inbox.svg`} alt="" width="24" height="24" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle ps-3">
                                                    <h6 className="mb-1 fs-3 fw-semibold lh-base">My Sites Guard</h6>
                                                    <span className="fs-2 d-block text-body-secondary">Messages &amp; Emails</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simplebar-placeholder" style={{width: "360px", height: "603px"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal" style={{visibility: "hidden"}}>
                        <div className="simplebar-scrollbar" style={{width: "0px", display: "none"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={{visibility: "hidden"}}>
                        <div className="simplebar-scrollbar" style={{height: "0px", display: "none"}}></div>
                    </div>
                </div>

            </div>
        </>
    )
});

export default closeOnClickOutside(MenuApps)
