import React, { useState } from "react";
import {marked} from "marked";
import PrismDecorator from "draft-js-prism";
import Prism from "prismjs";
import {EditorState} from "draft-js";

const parseTextBlock = (text) => {
    const lines = text.split('\n').map(line => line.trim());

    let question = '';
    let answers = [];
    let correctAnswer = '';
    let currentAnswer = '';
    let currentAnswerNum = 0
    let isQuestion = false;
    let isCorrectAnswer = false;

    lines.forEach(line => {
        if (line.startsWith('####')) {
            // Новая строка вопроса
            isQuestion = true;
            question += (question ? '\n' : '') + line.slice(4).trim();
        } else if (line.startsWith('#!')) {
            isQuestion = false;

            // Правильный ответ
            if (currentAnswer != "") answers.push(currentAnswer.trim());
            currentAnswerNum = answers.length + 1

            //isCorrectAnswer = true;
            currentAnswer = line.slice(2).trim();
        } else if (line.startsWith('#')) {
            isQuestion = false;

            // Обычный ответ
            if (currentAnswer != "") answers.push(currentAnswer.trim());

            //isCorrectAnswer = false;
            currentAnswer = line.slice(1).trim();
        } else {
            // Продолжение предыдущей строки
            if (isQuestion) {
                question += '\n' + line;
            } else if (isCorrectAnswer) {
                currentAnswer += ' ' + line.trim();
            } else {
                currentAnswer += ' ' + line.trim();
            }
        }
    });

    if (currentAnswer) answers.push(currentAnswer.trim());
    //if (isCorrectAnswer) correctAnswer = currentAnswer.trim();

    return {
        question,
        answers,
        correctAnswer,
        currentAnswerNum
    };
};

const TextParserComponent = (props) => {
    const [text, setText] = useState('');
    const [parsedData, setParsedData] = useState(null);

    const handleTextChange = (event) => {
        const newText = event.target.value;
        setText(newText);
        const parsed = parseTextBlock(newText);
        setParsedData(parsed);
    };

    return (
        <div>
            <h2>Text Parser</h2>
            <textarea
                rows="10"
                cols="50"
                value={text}
                onChange={handleTextChange}
                placeholder="Введите текст для парсинга"
            />

            <a href="javascript:void(0)" id="btn-add-contact" className="btn btn-primary d-flex align-items-center"
               onClick={() => {
                   console.log("parser ::: ", props.data)



                   props.setFieldsData({...props.data,
                       task_html: parsedData.question,
                       answer1_html: parsedData.answers[0],
                       answer2_html: parsedData.answers[1],
                       answer3_html: parsedData.answers[2],
                       answer4_html: parsedData.answers[3],
                       correct_answer: parsedData.currentAnswerNum,
                   })
               }}
               style={{marginTop: "5px", marginBottom: "15px", maxHeight: "40px", maxWidth: "150px",}}><i className="ti ti-users text-white me-1 fs-5"></i> Apply</a>

            {parsedData && (
                <div>
                    <h3>Question: {parsedData.question} ({parsedData.currentAnswerNum})</h3>
                    <ul>
                        {parsedData.answers.map((answer, index) => (
                            <li key={index} style={{ fontWeight: answer === parsedData.correctAnswer ? 'bold' : 'normal' }}>
                                {parsedData.currentAnswerNum == index+1 ? (<><b>{index+1}.</b> {answer}</>) : (<>{index+1}. {answer}</>)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TextParserComponent;
