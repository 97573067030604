import React from 'react';
import {Link} from "react-router-dom";
import {PUBLIC_URL} from './../../const'

const page404 = (props) => {
    return (
        <>
            <div id="main-wrapper">
                <div
                    className="position-relative overflow-hidden min-vh-100 w-100 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="row justify-content-center w-100">
                            <div className="col-lg-4">
                                <div className="text-center">
                                    <img src={`${PUBLIC_URL}/assets/images/backgrounds/errorimg.svg`} alt="" className="img-fluid" width="500"/>
                                    <h1 className="fw-semibold mb-7 fs-9">Opps!!!</h1>
                                    <h4 className="fw-semibold mb-7">This page you are looking for could not be found.</h4>
                                    
                                    <Link to="/dashboard" className="btn btn-primary"  role="button">Go Back toHome</Link>
   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dark-transparent sidebartoggler"></div>
   
            <script src="./assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
            <script src="./assets/libs/simplebar/dist/simplebar.min.js"></script>
            <script src="./assets/js/theme/app.init.js"></script>
            <script src="./assets/js/theme/theme.js"></script>
            <script src="./assets/js/theme/app.min.js"></script>
            <script src="./assets/js/theme/sidebarmenu.js"></script>
            
            <script src="https://cdn.jsdelivr.net/npm/iconify-icon@1.0.8/dist/iconify-icon.min.js"></script>
        </>
    )
}

export default page404
