import React, {useEffect, useState} from 'react';
import {convertFromRaw, EditorState} from "draft-js";

/*
<TagsInput values={fieldsData[f.key]} />

--


*/

const TagsInput = (props) => {
    const [tags, setTags] = useState([]);
    const [input, setInput] = useState('');

    const encodeBase64 = (str) => {
        // Преобразование строки в UTF-8
        const utf8String = unescape(encodeURIComponent(str));
        // Кодирование в Base64
        return btoa(utf8String);
    };
    const decodeBase64 = (str) => {
        // Декодирование из Base64
        const decodedString = atob(str);
        // Преобразование строки из UTF-8
        return decodeURIComponent(escape(decodedString));
    };


    useEffect(() => {
        try {
            const jsonData = decodeBase64(props.values)
            const tags = JSON.parse(jsonData);

            saveTags(tags)
        } catch (error) {
            ///
        }
    }, []);

    const saveTags = (tags) => {
        setTags(tags)

        let event = {target: {value: encodeBase64(JSON.stringify(tags))}};
        props.onChange(event, props.name || "tmpkey")
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const trimmedInput = input.trim();
            if (trimmedInput && !tags.includes(trimmedInput)) {
                saveTags([...tags, trimmedInput]);
            }


            setInput('');
        } else if (e.key === 'Backspace' && !input.length && tags.length) {
            const newTags = [...tags];
            newTags.pop();
            saveTags(newTags);
        }
    };

    const removeTag = (index) => {
        saveTags(tags.filter((_, i) => i !== index));
    };

    return (
        <div className="tags-input-container">
            {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="tag-text">{tag}</span>
                    <span className="tag-remove" onClick={() => removeTag(index)}>x</span>
                </div>
            ))}
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter a tag"
            />
        </div>
    );
};

export default TagsInput;
