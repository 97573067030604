import React, { useState, useEffect } from 'react';
import { Editor, EditorState, ContentState, Modifier, AtomicBlockUtils, stateFromHTML, convertToRaw, convertFromRaw, convertFromHTML, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import {marked} from 'marked';
import 'draft-js/dist/Draft.css';

/*
import Prism from 'prismjs';
import PrismDecorator from 'draft-js-prism';

import 'prismjs/themes/prism.css';

import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-shell-session';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-yaml';
*/
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';

import PrismDecorator from 'draft-js-prism';

// Важно: сначала темы и другие основные зависимости
import 'prismjs/components/prism-javascript'

const RichEditor = (props) => {
    const decorator = new PrismDecorator({
        prism: Prism,
        defaultLanguage: 'javascript', // Установите язык по умолчанию
    });

    const [editorState, setEditorState] = useState(() => {
        return EditorState.createEmpty()


        //const blocksFromHTML = convertFromHTML(props.initialValue || "");
        //const initialContentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        //return EditorState.createWithContent(initialContentState)
    });

    useEffect(() => {
        //console.log("RAW", JSON.parse(props.initialValue))
        if(typeof props.initialValue !== "undefined" && props.initialValue != ""){
            try {
                const data = JSON.parse(props.initialValue );
                if(data.raw) {
                    const jsonData = decodeBase64(data.raw)
                    //console.log(jsonData)

                    const raw = JSON.parse(jsonData);
                    const contentState = convertFromRaw(raw);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);
                }
            } catch (error) {
                ///
            }

            //const data = JSON.parse(props.initialValue);
            //console.log(data)
            /*if(data){
                const contentState = convertFromRaw(data);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }*/
        }
    }, []);

    // Функция для загрузки изображения
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const url = e.target.result;
                insertImage(url);
            };
            reader.readAsDataURL(file);
        }
    };

    // Функция для вставки изображения в редактор
    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src: url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        );
        setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter()));
    };

    const [isEditing, setIsEditing] = useState(false);

    const handleBeforeInput = () => {
        if (!isEditing) setIsEditing(true);
    };


    //const base64Encode = (str) => Buffer.from(str, 'utf-8').toString('base64');
    const encodeBase64 = (str) => {
        // Преобразование строки в UTF-8
        const utf8String = unescape(encodeURIComponent(str));
        // Кодирование в Base64
        return btoa(utf8String);
    };
    const decodeBase64 = (str) => {
        // Декодирование из Base64
        const decodedString = atob(str);
        // Преобразование строки из UTF-8
        return decodeURIComponent(escape(decodedString));
    };
    /*
    const encodeBase64 = (str) => {
        return btoa(str);
    };

    const decodeBase64 = (str) => {
        return atob(str);
    };
    */

    const [fieldsData, setFieldsData] = useState({})

    const handleOnBlur = () => {
        if (isEditing) setIsEditing(false);

        let rawContent = convertToRaw(editorState.getCurrentContent());
        const eBase64 = encodeBase64(JSON.stringify(rawContent, null, 2))

        /*
        const jsonData = decodeBase64(eBase64)
        console.log(jsonData)

        const data = JSON.parse(jsonData);
        const contentState = convertFromRaw(data);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        /*
        const encodedBlocks = rawContent.blocks.map(block => {
            if (block.text) {
                return {
                    ...block,
                    text: base64Encode(block.text)
                };
            }
            return block;
        });

        console.log("original", encodedBlocks);

        rawContent = {...rawContent, blocks: [...encodedBlocks] }
        console.log("new", rawContent);
*/
        const contentState = editorState.getCurrentContent();
        //const content = stateToHTML(contentState);
        const html = encodeBase64(stateToHTML(contentState))

        const value = JSON.stringify({raw: eBase64, html: html}, null, 2);
        let event = {target: {value: value}};

        props.fieldsChange(event, props.name || "tmpkey")
    };

    // Рендеринг блока изображения
    const blockRenderer = (block) => {
        if (block.getType() === 'atomic') {
            const contentState = editorState.getCurrentContent();
            const entity = contentState.getEntity(block.getEntityAt(0));
            const { src } = entity.getData();
            return {
                component: Media,
                editable: false,
                props: {
                    src,
                },
            };
        }
        if (block.getType() === 'code-block' && isEditing == false) {
            return {
                component: CodeBlock,
                editable: true, // или false, если не хотите, чтобы блок был редактируемым
                props: {
                    isEditing, // Передаем флаг редактирования
                },
            };
        }

        return null;
    };

    const getLanguageFromText = (text) => {
        const match = text.match(/```(\w+)/);
        return match ? match[1] : 'javascript'; // Устанавливаем язык по умолчанию, если не указан
    };

    const CodeBlock = (props) => {
        const text = props.block.getText();

        //const rawContent = convertToRaw(text);
        //console.log(text);

        // Извлекаем язык программирования
        const language = getLanguageFromText(text);

        // Очищаем текст от языка (если был указан)
        const cleanedText = text.replace(/```(\w+)\n?/, '');

        // Подсветка синтаксиса с помощью Prism.js
        const html = Prism.highlight(cleanedText, Prism.languages[language], language);

        return (
            <pre className={`language-${language}`}>
                <code
                    className={`language-${language}`}
                    dangerouslySetInnerHTML={{ __html: html }} />
            </pre>
        );
    };

    // Компонент для отображения медиа (изображений)
    const Media = (props) => {
        const { src } = props.blockProps;
        return <img src={src} alt="Загруженное изображение" style={{ maxWidth: '100%' }} />;
    };

    const onTab = (e) => {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, editorState, maxDepth));
    }

    //const setState = (newEditorState) => {
        //setEditorState(newEditorState)

        //console.log(" - debug - ", props)
        //const contentState = newEditorState.getCurrentContent();
        //const content = stateToHTML(contentState);
        //let event = {target: {value: content}};
        //props.fieldsChange(event, props.name || "tmpkey")
    //}

    const insertHTML = (editorState, html) => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        // Конвертация HTML в блоки контента
        const blocksFromHTML = convertFromHTML(html);
        const newContentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );

        // Вставка нового контента в текущее состояние редактора
        const withNewContent = Modifier.replaceWithFragment(
            contentState,
            selectionState,
            newContentState.getBlockMap()
        );

        // Создание нового состояния редактора с обновлённым содержимым
        const newEditorState = EditorState.push(editorState, withNewContent, 'insert-fragment');

        return newEditorState;
    };

    const handlePastedText = (text, html, editorState) => {
        if (text) {
            //const contentState = stateFromHTML(text);
            let htmlContent = marked(text);

//            htmlContent = `
//      <code class="language-javascript">
//      const greet = () => console.log('Hello, world!');
//      </code>
//    `;

            let newEditorState = insertHTML(editorState, htmlContent);


            // Добавляем декоратор Prism.js для подсветки синтаксиса
            const decorator = new PrismDecorator({
                prism: Prism,
                defaultLanguage: 'javascript', // Можно настроить по умолчанию
            });

            newEditorState = EditorState.set(newEditorState, { decorator });

            setEditorState(newEditorState);

            //console.log(" :: output :: ",htmlOutput)
            //const blocksFromHTML = convertFromHTML(htmlOutput);
            //const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            //const newEditorState = EditorState.createWithContent(contentState);

            //setState(newEditorState)

            return 'handled'; // Сообщаем Draft.js, что вставка обработана
        }

        return 'not-handled'; // Продолжаем стандартную обработку, если мы не обработали вставку
    };

    const handleKeyCommand = (command) => {
        //const {editorState} = this.state;
        const newEditorState = RichUtils.handleKeyCommand(editorState, command);
        if (newEditorState) {
            setEditorState(newEditorState)
            return true;
        }
        return false;
    }

    // Обработчик нажатия Enter
    const handleReturn = (e, editorState) => {
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const blockType = currentContent.getBlockForKey(selection.getStartKey()).getType();

        if (blockType === 'code-block') {
            const contentState = Modifier.insertText(
                currentContent,
                selection,
                '\n'
            );
            setEditorState(
                EditorState.push(editorState, contentState, 'insert-characters')
            );
            return 'handled'; // Сообщаем Draft.js, что событие обработано
        }

        return 'not-handled'; // Продолжаем стандартную обработку
    };
    //const focus = () => editor.focus();

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }

    try {
        if(fieldsData != props.data && props.data != "") {
            handlePastedText(props.data, null, editorState)
            setFieldsData(props.data)
        }
    } catch (error) {
        ///
    }



    return (
        <>
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={toggleBlockType}
                    handleImageUpload={handleImageUpload}
                />
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
                <div className={className}>
                    <Editor
                        editorState={editorState}
                        onChange={setEditorState}
                        onTab={onTab}
                        placeholder="Tell a story..."
                        spellCheck={true}
                        handlePastedText={handlePastedText}
                        blockRendererFn={blockRenderer}
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        handleKeyCommand={handleKeyCommand}
                        handleReturn={handleReturn} // Подключаем кастомный обработчик Enter
                        handleBeforeInput={handleBeforeInput}
                        onFocus={handleBeforeInput}
                        onBlur={handleOnBlur}
                    />
                </div>
            </div>
        </>
    )
}



// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    {label: 'Normal', style: 'unstyled'},
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) => {
                return <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            })}
            <input type="file" onChange={props.handleImageUpload} />
        </div>
    );
};

var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = (props) => {
    var currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

export default RichEditor
