import React, { useState } from 'react';

import BasicCRUD from './../core/basic-crud'
import {Link} from "react-router-dom";
import ChatsViews from "./old/chatsViews";
import PopUpDialog from "../core/popup-dialog";

import Sections from "./sections";

const Courses = (props) => {
    const object = "courses"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const DrawSatus = (data) => {
        const status = data['status'] == 1 ? "Published" : "Draft"

        return (
            <>
                {status}
            </>
        )
    }

    const columns = [
        {label:"Order", key: "list_num", width: "80px"},
        {label:"Name", key: "name"},
        {label:"Status", key: "status", width: "100px", format: DrawSatus,},
        {label:"Created", key: "created_at", width: "200px"},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const fields = [
        {
            type: "tab",
            name: "",
            image: "",
            fields: [
                [{label: "Name", key: "name", default_value: "", showLabel: "top",}],
                [{label: "Order", key: "list_num", default_value: "", showLabel: "top",}],
                [{label: "Status", key: "status", default_value: "", type: "selectbox", data:[{id: "0", name: "Draft"}, {id: "1", name: "Published"}], showLabel: "top"}],
                [{label: "Brief Description", key: "brief", default_value: "", type: "draftjs", showLabel: "top",}],
                [{label: "Description", key: "description", default_value: "", type: "draftjs", showLabel: "top",}],
                [{label: "Tags", key: "tags", default_value: "", type: "tags", showLabel: "top",}],
            ]
        },

    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
        {name: "sections", label:"Sections"},
    ]

    const [showSectionsDld, setShowSectionsDld] = useState(false);
    const [courseID, setCourseID] = useState(0);

    const tableAction = (action, data) => {
        if(action == "sections"){
            setCourseID(data['id'])
            setShowSectionsDld(true)
        }
    }

    const multiActions = [

    ]

    let filters = {course_id: courseID, test: 1}

    return (
        <>
            <PopUpDialog title={`Courses`} show={showSectionsDld} hideDialog={setShowSectionsDld}>
                <Sections filters={filters} />
            </PopUpDialog>

            <BasicCRUD
                title={"Courses"}
                subTitle={"add / edit course"}
                homeURL={"/dashboard"}
                addTitle="Add Course" editTitle={"Edit Course"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                tableAction={tableAction}
                multiActions={multiActions}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Course</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Course</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Course</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  Courses;
