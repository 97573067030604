import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router'
import { Route, Routes, useParams } from 'react-router-dom';

import { Editor } from '@tinymce/tinymce-react';

//import { Editor, EditorState, RichUtils } from 'draft-js';
//import 'draft-js/dist/Draft.css';
import RichEditor from './rich-editor'
import TagsInput from "./tag-input";


//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import Highlight from 'highlight.js';
//import 'highlight.js/styles/default.css'; // Выберите тему

//import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
//import codeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';

//import {CKEditor} from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
/*
<CKEditor
    editor={ClassicEditor}
    data={fieldsData[f.key]}
    onChange={(event, editor) => {let data = editor.getData() || "<p></p>"; console.log(" -- editor -- ", data); event = {target: {value: data}}; fieldsChange(event, f.key)}}
/>*/

const RenderOptions = (props) => {
    const response = props.response
    const fieldsData = props.fieldsData
    const fieldsChange = props.fieldsChange

    return (
        <>
            {props.fields ? props.fields.map(item => {
                var columns = 12 / item.length;
                //columns = item.columns || columns
/*
                const renderHighlightedData = () => {
                    const highlightedData = Highlight.highlightAuto(editorData).value;
                    return { __html: highlightedData };
                };
*/


                return (
                    <>
                        <div className={`row ${props.align && props.align == "center" ? "justify-content-center" : null}`}>
                            {item.map(f => {

                                const err = response.err && response.err[f.key] ? response.err[f.key] : ""
                                const fieldType = f.type || "input"
                                //console.log("err: ", err)

                                const newColumns = f.columns || columns

                                const errClass = err !== "" ? "is-invalid" : ""

                                const CustomField = f.fn || null

                                //console.log("Show Rule",props.showRule)
                                if(props.showRule){
                                    if(!props.showRule(fieldsData, f.key)) return null
                                }

                                //<div className={`col-md-${newColumns} d-flex`}>
                                return (
                                    <>
                                        <div className={`col-md-${newColumns}`}>
                                                {fieldType == "custom" && CustomField ? (
                                                    <>
                                                        <CustomField data={fieldsData} />
                                                    </>
                                                ) : null}

                                                {fieldType == "group" ? (
                                                    <>
                                                        <div className="card w-100 position-relative overflow-hidden">
                                                            <div className="card-body p-4">
                                                                <h5 className="card-title fw-semibold">{f.label}</h5>
                                                                <p className="card-subtitle mb-4">{f.subLabel}</p>
                                                                <div>
                                                                    <RenderOptions showRule={props.showRule} fields={f.fields} response={response} fieldsData={fieldsData} fieldsChange={fieldsChange} enterApply={props.enterApply} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}

                                            {fieldType == "switch" ? (
                                                    <>
                                                        <div style={{width: "100%"}}>
                                                        <div
                                                            className="d-flex align-items-center justify-content-between mb-4">
                                                            <div className="d-flex align-items-center gap-3">
                                                                <div className="text-bg-light rounded-1 p-6 d-flex align-items-center justify-content-center">
                                                                    <i className={`ti ${f.icon} text-dark d-block fs-7`} width="22" height="22"></i>
                                                                </div>
                                                                <div>
                                                                    <h5 className="fs-4 fw-semibold">{f.label}</h5>
                                                                    <p className="mb-0">{f.subLabel}</p>
                                                                </div>
                                                            </div>
                                                            <div className="form-check form-switch mb-0">
                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </>
                                                    ): null
                                            }
                                                {fieldType == "input" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.showLabel && f.showLabel == "left" && <label className="form-label fw-semibold col-sm-3 col-form-label">{f.label}</label>}
                                                            {f.showLabel && f.showLabel == "top" && <label htmlFor="fname1" className="control-label col-form-label">{f.label}</label>}
                                                            {f.beforeField && <>{f.beforeField}</>}
                                                            <input style={{width: "100%", ...f.styleField}} autoComplete="" type="text" className={`form-control ${f.classField} ${errClass}`} placeholder={`${f.label}`}  value={fieldsData[f.key]} onChange={(event) => fieldsChange(event, f.key)} onKeyPress={props.enterApply} />
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>

                                                    </>
                                                ) : null}
                                                {fieldType == "password" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            <input style={{width: "100%"}} autoComplete="" type="password" className={`form-control ${errClass}`} placeholder={`${f.label}`}  value={fieldsData[f.key]} onChange={(event) => fieldsChange(event, f.key)} />
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {fieldType == "selectbox" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.label && f.label != "" && <label className="form-label">{f.label}</label>}
                                                            <select style={{width: "100%"}} className="form-select" onChange={(event) => {
                                                                //fieldsChange(event, f.key);
                                                                //props.enterApply({key: "Enter"})

                                                                //fieldsChange(event, f.key)
                                                               // console.log("//select/event", event)
                                                                fieldsChange(event, f.key)
                                                                    //.then(result => {
                                                                    //console.log(" //  - - - cool select result - - - // ", result)
                                                                    //props.enterApply({key: "Enter", data: {...result}})
                                                                //})
                                                            }}>
                                                                {f.data.map((item) => {
                                                                    // console.log("::: item ::: - >>>> ", fieldsData[f.key][item.id])
                                                                    //console.log("fieldsData ::: ", fieldsData[f.key], item.id);
                                                                    var checked = fieldsData && fieldsData[f.key] == item.id
                                                                    return (
                                                                        <option value={item.id} selected={checked}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {fieldType == "tags" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.label && f.label != "" && <label className="form-label">{f.label}</label>}
                                                            <TagsInput name={f.key} values={fieldsData[f.key]} onChange={(event) => fieldsChange(event, f.key)} />
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {fieldType == "textarea" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.showLabel && f.showLabel == "left" && <label className="form-label fw-semibold col-sm-3 col-form-label">{f.label}</label>}
                                                            {f.showLabel && f.showLabel == "top" && <label htmlFor="fname1" className="control-label col-form-label">{f.label}</label>}
                                                            {f.beforeField && <>{f.beforeField}</>}

                                                            <textarea id="text2-3" rows={`${f.rows || 5}`} placeholder={`${f.label}`} className={`form-control ${errClass}`} onChange={(event) => fieldsChange(event, f.key)}>{fieldsData[f.key]}</textarea>
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>
                                                    </>
                                                ) : null}

                                                {fieldType == "tinymce" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.showLabel && f.showLabel == "left" && <label className="form-label fw-semibold col-sm-3 col-form-label">{f.label}</label>}
                                                            {f.showLabel && f.showLabel == "top" && <label htmlFor="fname1" className="control-label col-form-label">{f.label}</label>}
                                                            {f.beforeField && <>{f.beforeField}</>}

                                                            <Editor
                                                                apiKey="8pnvhwk2kro7frzyt22iiy6cybi2pxoq6vdywgyhwlupfvwy"
                                                                initialValue={fieldsData[`init_${f.key}`]}

                                                                init={{
                                                                    height: 500,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist autolink lists link image charmap print preview anchor',
                                                                        'searchreplace visualblocks code fullscreen',
                                                                        'insertdatetime media table paste code help wordcount',
                                                                        'codesample'
                                                                    ],
                                                                    toolbar:
                                                                        'undo redo | formatselect | bold italic backcolor | \
                                                                        alignleft aligncenter alignright alignjustify | \
                                                                        bullist numlist outdent indent | removeformat | codesample | help',
                                                                    codesample_languages: [
                                                                        { text: 'HTML/XML', value: 'markup' },
                                                                        { text: 'JavaScript', value: 'javascript' },
                                                                        { text: 'CSS', value: 'css' },
                                                                        { text: 'PHP', value: 'php' },
                                                                        { text: 'Python', value: 'python' },
                                                                        { text: 'SQL', value: 'sql' },
                                                                        { text: 'Ruby', value: 'ruby' },
                                                                        { text: 'Java', value: 'java' },
                                                                        { text: 'C', value: 'c' },
                                                                        { text: 'C#', value: 'csharp' },
                                                                        { text: 'C++', value: 'cpp' }
                                                                    ],
                                                                    paste_as_text: true,  // Вставляет весь текст как обычный текст, избегая нестандартного форматирования
                                                                    paste_preprocess: function(plugin, args) {
                                                                        // Обрабатываем вставку и проверяем, если текст содержит код, оформляем его как код
                                                                        const codePattern = /```([\s\S]*?)```/g; // Обнаружение блоков кода, заключенных в ```
                                                                        let content = args.content;

                                                                        content = content.replace(codePattern, (match, code) => {
                                                                            return `<pre class="language-php" contenteditable="true" data-mce-highlighted="true">${code.trim()}</pre>`
                                                                        });

                                                                        // Устанавливаем обработанный контент
                                                                        args.content = content;
                                                                    },
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}

                                                                onEditorChange={(content, editor) => {
                                                                    console.log('Content was updated:', content);
                                                                    let event = {target: {value: content}};
                                                                    fieldsChange(event, f.key)
                                                                }}
                                                            />
                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>

                                                    </>
                                                ) : null}

                                                {fieldType == "draftjs" ? (
                                                    <>
                                                        <div className="mb-3" style={{width: "100%"}}>
                                                            {f.showLabel && f.showLabel == "left" && <label className="form-label fw-semibold col-sm-3 col-form-label">{f.label}</label>}
                                                            {f.showLabel && f.showLabel == "top" && <label htmlFor="fname1" className="control-label col-form-label">{f.label}</label>}
                                                            {f.beforeField && <>{f.beforeField}</>}

                                                            <RichEditor initialValue={fieldsData[`init_${f.key}`]} data={fieldsData[`${f.key}_html`] || ""} fieldsChange={fieldsChange} name={f.key}/>

                                                            {err != "" ? <span className="validation-text text-danger" style={{display: "block"}}>{err}</span> : null}
                                                        </div>

                                                    </>
                                                ) : null}

                                                {fieldType == "checkbox" ? (() => {

                                                    return (
                                                        <>
                                                            <div className="mb-3 contact-location" style={{...f.styleField}}>
                                                                {f.label && f.label != "" && <p>{f.label}:</p>}

                                                                {f.data.map((item) => {
                                                                    // console.log("::: item ::: - >>>> ", fieldsData[f.key][item.id])
                                                                    //console.log("fieldsData ::: ", fieldsData);
                                                                    var checked = fieldsData && fieldsData[f.key] && fieldsData[f.key].includes(item.id)
                                                                    return (
                                                                        <label class={`col-md-12`}>
                                                                            <input type="checkbox" style={{marginRight: "10px"}} checked={checked} onChange={(event) => {
                                                                                fieldsChange(event, f.key, item.id).then(result => {
                                                                                    //console.log(" //  - - - cool result - - - // ", result)
                                                                                    props.enterApply({key: "Enter", data: {...result}})
                                                                                })
                                                                            }} />
                                                                            {item.name}
                                                                        </label>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    )})() : null}
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </>
                )
            }) : null}
        </>
    )
}

const RenderFormFields = (props) => {
    const { tab } = useParams();
    let curTab = tab - 1

    //console.log(" ### tab ### ", tab)

    const tabs = props.fields || []
    const fieldsData = props.fieldsData || {}
    const fieldsChange = props.fieldsChange

    const [active, setActive] = useState(0)

    const response = props.response
    //console.log("response ------------------------------------")
    //console.log(response)


    let style = {}
    if (props.isForm) {
        style = {marginBottom: "40px"}
    }

    const navigate = useNavigate();

    let borderClass = "bordered"
    if (props.hideBorders){
        borderClass = ""
    }

    return (
        <>
            {tabs && tabs.length > 1 ? (
            <ul className="nav nav-tabs" role="tablist">
                {tabs.map((item, index) => {
                    var classActive = ""

                    if (!props.isForm){
                        curTab = active
                    }

                    if (curTab == index){
                        //console.log(" ### active ### ", index)
                        classActive = "active"
                    }

                    /* calculate errors count per page */

                    let errorNumber = 0
                    if (response.err){
                        item.fields.forEach((fieldsGroup) => {
                            fieldsGroup.forEach((field) => {
                                const err = response.err[field.key] || ""
                                if (err !== ""){
                                    errorNumber++
                                }
                            })
                        })
                    }

                    return (
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link d-flex ${classActive}`} data-bs-toggle="tab" href="javascript:void(0)" role="tab" aria-selected="false" tabIndex="-1" onClick={() => {setActive(index); if (props.isForm){navigate(`/my-profile/${index + 1}`, { replace: true });}}}>
                                <span><i className={`${item.image}`}></i></span>
                                <span className="d-none d-md-block ms-2">{item.name}{errorNumber>0 ? <> <b style={{color:"red"}}>({errorNumber})</b> </> : null}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>) : null}


            <div className={`tab-content ${borderClass} mt-2`} style={style}>
            {tabs ? tabs.map((tab, index) =>{
                var classActive = ""

                if (!props.isForm){
                    curTab = active
                }

                if (curTab == index){
                    classActive = "active show"
                }

                return (
                    <>
                        <div className={`tab-pane p-3 ${classActive}`} id="navpill-11" role="tabpanel">
                            <RenderOptions showRule={tab.showRule} fields={tab.fields} align={tab.align} response={response} fieldsData={fieldsData} fieldsChange={fieldsChange} enterApply={props.enterApply} />

                            {props.isForm && <div className="col-12">
                                <div className="d-flex align-items-center justify-content-end gap-6">
                                    <button className="btn btn-primary" onClick={props.saveAction || (() => {console.log("please configure save action")})}>Save</button>
                                    <button className="btn bg-danger-subtle text-danger" onClick={props.cancelAction || (() => {console.log("please configure cancel action")})}>Cancel</button>
                                </div>
                            </div>}

                        </div>
                    </>
                )

            }) : null}
            </div>
        </>
    )
}

export default RenderFormFields
