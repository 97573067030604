import io from 'socket.io-client';
import AppContext from "./app-context"
import { BACKEND_URL, BACKEND_PATH } from "./const"

const socket = io(`${BACKEND_URL}`, {
    path: `${BACKEND_PATH}`,
});

const requestData = (object, args, fn = 'list', responsePrefix = "") =>{
    const page = 1
    AppContext.pages[object] = {...AppContext.pages[object], pageNum: page}

    const search = JSON.parse(localStorage.getItem(`${object}_search`))
        
    //const simple_search_text = localStorage.getItem(`${object}_simple_search_text`) || ""; simple_search_text: simple_search_text
    
    let searchData = {}
    search && Object.keys(search).forEach((field) => {
        let propName = `simple_search_${field}`
        searchData = {...searchData, [propName]: search[field]}
        //localStorage.setItem(`${props.object}_${field}`, props.fieldsData[data])
    })
    
    const data = {token: AppContext.user.token, page: page, items_count: 10, ...searchData, response_prefix: responsePrefix, ...args}

    if (`${object}:${fn}` == "history:list"){
        console.trace("I'm here")
    }

    socket.emit(`${object}:${fn}`, data);
}

const listener = (object, setState) => {
    let fn = "list"
    if(object.fn && object.fn != "") fn = object.fn

    socket.on(`${object.name}:${fn}-data`, (data) => {
        setState(data)
    });

    return () => {
        socket.off(`${object.name}:${fn}-data`);
    };
}

const socketUseEffect = (objectsList) => {
    objectsList.forEach((object) => {
        let responsePrefix = object.responsePrefix || ""
        if (responsePrefix != "") responsePrefix = `-${responsePrefix}`
        socket.on(`${object.name}:${object.fn}-data${responsePrefix}`, (data) => {
            object.saveDataFN(data)
        });

        requestData(object.name, object.args, object.fn, object.responsePrefix || "");

        if( object.interval &&  object.interval > 999 ){
            setInterval(() => {
                requestData(object.name, object.args || {}, object.fn, object.responsePrefix || "");
            }, object.interval);
        }
    });

    return () => {
        objectsList.forEach((object) => {
            let responsePrefix = object.responsePrefix || ""
            if (responsePrefix != "") responsePrefix = `-${responsePrefix}`

            socket.off(`${object.name}:${object.fn}-data${responsePrefix}`);
        });
    };
}

export {socket, requestData, listener, socketUseEffect}
