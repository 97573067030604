import {Link} from "react-router-dom";
import {PUBLIC_URL} from './../../const'

const PageTitle = (props) => {
    return (
        <>
            <div className="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">{props.title}</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={props.homeUrl} className="text-muted text-decoration-none">
                                            <span className="hide-menu">Home</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">{props.subTitle || props.title}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img src={`${PUBLIC_URL}/assets/images/breadcrumb/ChatBc.png`} alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageTitle
