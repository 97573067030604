import React, { useState, forwardRef} from 'react';
import closeOnClickOutside from './../core/close-on-click-outside'
import {PUBLIC_URL} from './../../const'

const MenuLanguage = forwardRef(({ open, setOpen }, ref) => {
    
    var openClass = ""
    if (open){
        openClass = "show"
    }
    
    return (
        <>
            <a
                className="nav-link nav-icon-hover"
                href="javascript:void(0)"
                onClick={() => {
                    setOpen(!open)
                }}
                id="drop2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <img src={`${PUBLIC_URL}/assets/images/svgs/icon-flag-en.svg`} alt="" width="20px" height="20px" className="rounded-circle object-fit-cover round-20" />
            </a>
            <div className={`dropdown-menu dropdown-menu-end dropdown-menu-animate-up  ${openClass}`} aria-labelledby="drop2"
                 data-bs-popper="static" ref={ref}>
                <div className="message-body">
                    <a href="javascript:void(0)" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item" onClick={() => {setOpen(false)}}>
                        <div className="position-relative">
                            <img src={`${PUBLIC_URL}/assets/images/svgs/icon-flag-en.svg`} alt="" width="20px" height="20px" className="rounded-circle object-fit-cover round-20" />
                        </div>
                        <p className="mb-0 fs-3">English</p>
                    </a>
                    <a href="javascript:void(0)" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item" onClick={() => {setOpen(false)}}>
                        <div className="position-relative">
                            <img src={`${PUBLIC_URL}/assets/images/svgs/icon-flag-ru.svg`} alt="" width="20px" height="20px" className="rounded-circle object-fit-cover round-20" />
                        </div>
                        <p className="mb-0 fs-3">Russian</p>
                    </a>
                    <a href="javascript:void(0)" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item" onClick={() => {setOpen(false)}}>
                        <div className="position-relative">
                            <img src={`${PUBLIC_URL}/assets/images/svgs/icon-flag-ua.svg`} alt="" width="20px" height="20px" className="rounded-circle object-fit-cover round-20" />
                        </div>
                        <p className="mb-0 fs-3">Ukraine</p>
                    </a>
                </div>
            </div>
        </>
    )    
});

export default closeOnClickOutside(MenuLanguage)
