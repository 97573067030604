import React, { useState } from 'react';

import BasicCRUD from './../core/basic-crud'
import {Link} from "react-router-dom";
import PopUpDialog from "../core/popup-dialog";

import Tasks from "./tasks";

const Sections = (props) => {
    const object = "sections"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const DrawSatus = (data) => {
        const status = data['status'] == 1 ? "Published" : "Draft"

        return (
            <>
                {status}
            </>
        )
    }

    const columns = [
        {label:"Order", key: "list_num", width: "80px"},
        {label:"Name", key: "name"},
        {label:"Status", key: "status", width: "100px", format: DrawSatus,},
        {label:"Created", key: "created_at", width: "200px"},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const fields = [
        {
            type: "tab",
            name: "",
            image: "",
            fields: [
                [{label: "Name", key: "name", default_value: "", showLabel: "top"}],
                [{label: "Order", key: "list_num", default_value: "", showLabel: "top"}],
                [{label: "Status", key: "status", default_value: "", type: "selectbox", data:[{id: "0", name: "Draft"}, {id: "1", name: "Published"}], showLabel: "top"}],
                [{label: "Is Exam", key: "is_exam", default_value: "", type: "selectbox", data:[{id: "0", name: "No"}, {id: "1", name: "Yes"}], showLabel: "top"}],
                [{label: "Description", key: "description", default_value: "", type: "draftjs", showLabel: "top",}],
            ]
        },

    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
        {name: "tasks", label:"Tasks"},
    ]

    const multiActions = [

    ]

    const [showTasksDld, setShowTasksDld] = useState(false);
    const [sectionID, setSectionID] = useState(0);
    const [sectionType, setSectionType] = useState(0);

    const tableAction = (action, data) => {
        if(action == "tasks"){
            setSectionID(data['id'])
            setSectionType(data['is_exam'])
            setShowTasksDld(true)
        }
    }

    let filters = {section_id: sectionID, course_id: props.filters.course_id, is_exam: sectionType}

    console.log("filters props", props.filters)

    return (
        <>
            <PopUpDialog title={`Tasks`} show={showTasksDld} hideDialog={setShowTasksDld}>
                <Tasks filters={filters} />
            </PopUpDialog>

            <BasicCRUD
                hideTitle={true}
                hideGroupActions={true}
                disableSaveFilters={true}
                args={props.filters || {}}

                title={"Sections"}
                subTitle={"add / edit sections"}
                homeURL={"/dashboard"}
                addTitle="Add Section" editTitle={"Edit Section"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                tableAction={tableAction}
                filters={props.filters || {}}
                multiActions={multiActions}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Sections</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Sections</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Sections</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  Sections;
