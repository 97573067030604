import React, { useState, useEffect } from 'react';

import Table from './table'
import PageTitle from "./page-title"
import Dialog from './dialogs'
import RenderFormFields from './forms'

import { socket, requestData } from './../../socket';
import AppContext from "./../../app-context"

import {decode} from 'html-entities';

const BasicCRUD = (props) => {
    const object = props.object
    const fn = props.fn || "list"
    const args = props.args || {}

    const [data, setData] = useState([]);

    useEffect(() => {
        if(props.ownHandler){
            socket.on(`${object}:${fn}-data`, (data) => {
                setData(data)
            });

            requestData(object, args, fn);
        }

        const genData = (data) => {
            let newData = {}

            if (data['id']) newData['id'] = data['id']

            for(var x=0;x<fields.length;x++){
                console.log(" num ------------------ ", x, fields[x].fields)
                for(var z=0;z<fields[x].fields.length;z++){
                    for(var i=0;i<fields[x].fields[z].length;i++) {
                        const item = fields[x].fields[z][i]
                        const key = item.key

                        if (data[key]){

                            if (item.type == "tinymce" || item.type == "draftjs" || item.type == "textarea"){
                                newData[key] = decode(data[key])
                                newData[`init_${key}`] = decode(data[key])
                            }else{
                                newData[key] = data[key]
                            }
                        }
                    }
                }
            }

            return newData
        }

        socket.on(`${object}:create-response`, (data) => {
            if (data.status == 1){
                if((props.noCreateDialog || false) == false) {
                    data['created'] = 1
                    setResponse(data)

                    AppContext.pages[object].pageNum = data.page
                    setPageNum(data.page)
                    setResponse(data)

                    closeCreateUserDialog()
                }
            }

            console.log(`${object}:create-response`, data)
        })

        socket.on(`${object}:create-data`, (data) => {
            if (data.status == 1){
                if((props.noCreateDialog || false) == true) {
                    let newData = genData(data.data[0])

                    setFieldsData({...newData})

                    showDialog(true);
                }
            }
        })

        socket.on(`${object}:update-response`, (data) => {

            data['updated'] = 1
            setResponse(data)

            if (data.status == 1){
                closeCreateUserDialog()
            }

            console.log(`${object}:update-response`, data)
        })

        socket.on(`${object}:delete-response`, (data) => {
            data['delete'] = 1


            console.log(`${object}:delete-response`, data)

            AppContext.pages[object].pageNum = data.page
            setPageNum(data.page)
            setResponse(data)

            console.log(`${object}:delete-response`, data)
        })

        return () => {
            if(props.ownHandler) {
                socket.off(`${object}:${fn}-data`)
            }
            socket.off(`${object}:create-response`);
            socket.off(`${object}:update-response`);
            socket.off(`${object}:delete-response`);
        };
    }, []);

    const columns = props.columns || []
    const fields = props.fields || []

    const [dialog, showDialog] = useState(false);

    const response = props.response
    const setResponse = props.setResponse
    const fieldsData = props.fieldsData
    const setFieldsData = props.setFieldsData

    function fieldsChange(event, key, item_id=null){
        var data = {...fieldsData}
        if(item_id == null) {
           data = {...data, [key]: event.target.value}
        }else{
            console.log("push ::: ", data, key)
            if (!data[key]) data[key] = []
            if(data[key].includes(item_id)){
                data[key] = data[key].filter(e => e !== item_id)
            }else{
                data[key].push(item_id)
            }

        }

        setFieldsData(data)

        console.log("fields data::::", data)
    }

    const [pageNum, setPageNum] = useState(AppContext.pages && AppContext.pages[object] && AppContext.pages[object].pageNum || 1)

    function handleAddButton() {
        console.log("handle add button :: ", props.noCreateDialog);

        if((props.noCreateDialog || false) == false) {
            setResponse([]);
            setFieldsData({});
            showDialog(true);
        }else{
            var data = {
                token: AppContext.user.token,
                page: AppContext.pages[object].pageNum,
                items_count: AppContext.rows_per_page,
                fields: {...props.args || {}},
                ...searchData,
                ...props.args || {},
            };

            socket.emit(`${object}:create`, data);
        }
    }

    function closeCreateUserDialog() {showDialog(false);}

    const [searchData, setSearchData] = useState(() => {
        const simple_search = localStorage.getItem(`${object}_search`) || {};
        return simple_search
    })

    function saveCreateUserDialog() {
        var tmpFieldsData = fieldsData

        for(var i=0;i<fields.length;i++){
            tmpFieldsData[fields[i].key] = tmpFieldsData[fields[i].key] || fields[i].default_value
        }

        console.log("Fields Data: ", tmpFieldsData)

        var data = {
            token: AppContext.user.token,
            page: AppContext.pages[object].pageNum,
            items_count: AppContext.rows_per_page,
            fields: {...props.args || {}, ...tmpFieldsData},
            ...searchData,
            ...props.args || {},
            //simple_search_text: searchData
        };

        if (data.fields.id > 0){
            socket.emit(`${object}:update`, data);
        }else{
            socket.emit(`${object}:create`, data);
        }
    }

    const [selectedItems, setSelectedItems] = useState({})

    const crudTableAction = (action, data) => {
        console.log("crud table actions", action, data)

        if (action == "edit"){
            setResponse([]);

            /* ----- ----- ----- */


            let newData = {}
            if (data['id']) newData['id'] = data['id']

            for(var x=0;x<fields.length;x++){
                console.log(" num ------------------ ", x, fields[x].fields)
                for(var z=0;z<fields[x].fields.length;z++){
                    for(var i=0;i<fields[x].fields[z].length;i++) {
                        const item = fields[x].fields[z][i]
                        const key = item.key

                        if (data[key]){

                            if (item.type == "tinymce" || item.type == "draftjs" || item.type == "textarea"){
                                newData[key] = decode(data[key])
                                newData[`init_${key}`] = decode(data[key])
                            }else{
                                newData[key] = data[key]
                            }
                        }
                    }
                }
            }

            //console.log("-- FIELDS -- :: ", fields, data, newData)
            //console.log("-- DATA -- :: ", data, newData)

            setFieldsData({...newData})

            showDialog(true);
        }

        if (action == "delete"){
            var data = {
                token: AppContext.user.token,
                page: AppContext.pages[object].pageNum,
                items_count: AppContext.rows_per_page,
                fields: data,
                ...props.args || {},
            };

            if (window.confirm('Are you sure you wish to delete this item?')){
                socket.emit(`${object}:delete`, data);
            }
        }

        if (action == "multiDelete"){
            var data = {
                token: AppContext.user.token,
                page: AppContext.pages[object].pageNum,
                items_count: AppContext.rows_per_page,
                fields: {},
                ...props.args || {},
            };

            data.fields = {...props.data.data[0], id: []}

            let choosedItems = 0
            props.data.data.forEach((item, index) => {
                if(selectedItems[`item_${item.id}`]){
                    data.fields.id.push(item.id)
                    choosedItems++
                }
            })

            if (choosedItems > 0){
                if (window.confirm('Are you sure you wish to delete selected items?')){
                    socket.emit(`${object}:delete_All`, data);
                }
            }else{
                window.alert('Please, choose items!')
            }

            console.log("debug", data, selectedItems, props.data.data)

        }

        if (props.tableAction){
            props.tableAction(action, data)
        }


    }

    const tableAction = crudTableAction

    const actions = props.actions
    const multiActions = props.multiActions

    const hideTitle = props.hideTitle || false

    let tableData = props.data || []
    if(props.ownHandler){
        tableData = data
    }

    console.log(":: table data :: ", tableData)

    return (
        <>
            {dialog ? (
                <Dialog title={props.addTitle}
                        editTitle={props.editTitle}
                        fieldsData={fieldsData}
                        closeDialog={closeCreateUserDialog}
                        saveDialog={saveCreateUserDialog} >

                    <div className="flex gap-10 flex-column">
                        <div className="form-group col-md-12">
                            <RenderFormFields fields={fields} fieldsData={fieldsData} fieldsChange={fieldsChange} response={response} />
                        </div>

                    </div>
                </Dialog>
            ) : null}


            {!hideTitle && <PageTitle title={props.title} subTitle={props.subTitle || props.title} homeUrl={props.homeURL} />}

            {props.children}

            <div>
                <Table object={object}
                       args={props.args || {}}
                       filters={props.filters || {}}
                       pageNum={pageNum}
                       setPageNum={setPageNum}
                       setResponse={setResponse}
                       searchData={searchData}
                       setSearchData={setSearchData}
                       addTitle={props.addTitle}
                       addFunction={handleAddButton}
                       columns={columns} data={tableData}
                       selectedItems={selectedItems} setSelectedItems={setSelectedItems}
                       hideActions={props.hideActions || false}
                       disableSaveFilters={props.disableSaveFilters || false}
                       actions={actions} tableAction={tableAction}
                       hideGroupActions={props.hideGroupActions || false} multiActions={multiActions}
                       searchFields={props.searchFields || null}
                />
            </div>
        </>
    )
}

export default BasicCRUD
