import React from 'react';

import Header from "./header"
import Footer from "./footer"
import LeftMenu from "./left-menu"
import {PUBLIC_URL} from './../../const'

const MainFrame = (props) => {
    return (
        <>
            <div class="preloader" style={{display: "none"}}>
                <img src={`${PUBLIC_URL}/assets/images/logos/favicon.png`} alt="loader" class="lds-ripple img-fluid" />
            </div>
            <div id="main-wrapper">
                <aside class="left-sidebar with-vertical">
                    <div>
                        <LeftMenu counters={props.counters || {}} />
                    </div>
                </aside>
                <div class="page-wrapper">
                    <header class="topbar">
                        <div class="with-vertical">
                            <Header />
                        </div>
                        <div class="app-header with-horizontal">

                        </div>
                    </header>

                    <aside class="left-sidebar with-horizontal">

                    </aside>

                    <div class="body-wrapper">
                        <div class="container-fluid">
                            {props.children}
                        </div>
                    </div>

                </div>
            </div>
            <div class="dark-transparent sidebartoggler"></div>
        </>
    )
}

export default MainFrame
