import React from "react";

const AppContext = {
    user: {
        id: "",
        name: "",
        role: "",
        email: "",
        token: "123456",
    },
    tariff: {
        name: "Unlimited Access",
    },
    rows_per_page: 10,
    pages:{
        agents:{
            pageNum: 1,
        },
        departments:{
            pageNum: 1,
        },
        abbreviations:{
            pageNum: 1,
        }
    }
};

export default AppContext;
