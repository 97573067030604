import React, { useState, useEffect } from 'react';

const Dialog = (props) => {
    //const saveDialog = props.saveDialog

    return (
        <>
            <div className="modal fade show" id="addContactModal" tabIndex="-1" role="dialog"
                 aria-labelledby="addContactModalTitle" aria-modal="true" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header d-flex align-items-center">
                            <h5 className="modal-title">{props.fieldsData['id'] > 0 ? props.editTitle : props.title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeDialog}></button>
                        </div>
                        <div className="modal-body">
                            <div className="add-contact-box">
                                <div className="add-contact-content">
                                    <div id="addContactModalTitle">
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="d-flex gap-6 m-0">
                                <button id="btn-edit" className="btn btn-primary" onClick={props.saveDialog}>Save</button>
                                <button className="btn bg-danger-subtle text-danger" data-bs-dismiss="modal" onClick={props.closeDialog}> Discard</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dialog
