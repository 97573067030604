import React from 'react';
import MenuUserProfile from './menu-user-profile'
import MenuApps from './menu-apps'
import MenuLanguage from './menu-language'
import {PUBLIC_URL} from './../../const'

const Header = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg p-0">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a
                            className="nav-link sidebartoggler nav-icon-hover ms-n3"
                            id="headerCollapse"
                            href="javascript:void(0)"
                        >
                            <i className="ti ti-menu-2"></i>
                        </a>
                    </li>
                    <li className="nav-item d-none d-lg-block">
                        <a
                            className="nav-link nav-icon-hover"
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                        >
                            <i className="ti ti-search"></i>
                        </a>
                    </li>
                </ul>

                <ul className="navbar-nav quick-links d-none d-lg-flex">
                    <li className="nav-item dropdown-hover d-none d-lg-block">
                        <MenuApps />
                    </li>
                </ul>

                <div className="d-block d-lg-none">
                    <img src={`${PUBLIC_URL}/assets/images/logos/dark-logo.svg`} width="180" alt=""/>
                </div>
                <a
                    className="navbar-toggler nav-icon-hover p-0 border-0"
                    href="javascript:void(0)"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
    <span className="p-2">
      <i className="ti ti-dots fs-7"></i>
    </span>
                </a>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <div className="d-flex align-items-center justify-content-between">
                        <a
                            href="javascript:void(0)"
                            className="nav-link d-flex d-lg-none align-items-center justify-content-center"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#mobilenavbar"
                            aria-controls="offcanvasWithBothOptions"
                        >
                            <i className="ti ti-align-justified fs-7"></i>
                        </a>
                        <ul
                            className="navbar-nav flex-row ms-auto align-items-center justify-content-center"
                        >

                            <li className="nav-item">
                                <a className="nav-link moon dark-layout" href="javascript:void(0)">
                                    <iconify-icon icon="solar:moon-line-duotone" className="moon fs-7"></iconify-icon>
                                </a>
                                <a className="nav-link sun light-layout" href="javascript:void(0)">
                                    <iconify-icon icon="solar:sun-2-line-duotone" className="sun fs-7"></iconify-icon>
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <MenuLanguage />
                            </li>

                            <li className="nav-item dropdown">
                                <MenuUserProfile />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header
