const PopUpDialog = (props) => {
    return (
        <>
            {props.show && <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                                tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog"
                                style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header d-flex align-items-center">
                            <h4 className="modal-title" id="myLargeModalLabel">{props.title}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.hideDialog(!props.show)}></button>
                        </div>
                        <div className="modal-body">
                            {props.children}
                        </div>
                        <div className="modal-footer">
                            {props.saveDialogAction && <button id="btn-edit" className="btn btn-success rounded-pill" onClick={props.saveDialogAction}>{props.saveButtonTitle || "Save"}</button>}
                            <button type="button" className="btn bg-danger-subtle text-danger  waves-effect text-start" data-bs-dismiss="modal" onClick={() => props.hideDialog(!props.show)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default PopUpDialog
