import {PUBLIC_URL} from './../../const'

const Login = () => {
    return (
        <>
            <div id="main-wrapper">
                <div className="position-relative overflow-hidden radial-gradient min-vh-100 w-100">
                    <div className="position-relative z-index-5">
                        <div className="row">
                            <div className="col-xl-7 col-xxl-8">
                                <a href="../main/index.html" className="text-nowrap logo-img d-block px-4 py-9 w-100">
                                    <img src={`${PUBLIC_URL}/assets/images/logos/dark-logo.svg`} className="dark-logo" alt="Logo-Dark" />
                                    <img src={`${PUBLIC_URL}/assets/images/logos/light-logo.svg`} className="light-logo" alt="Logo-light" />
                                </a>
                                <div className="d-none d-xl-flex align-items-center justify-content-center h-n80">
                                    <img src={`${PUBLIC_URL}/assets/images/backgrounds/login-security.svg`} alt="" className="img-fluid" width="500" />
                                </div>
                            </div>
                            <div className="col-xl-5 col-xxl-4">
                                <div
                                    className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                                    <div className="auth-max-width col-sm-8 col-md-6 col-xl-7 px-4">
                                        <h2 className="mb-1 fs-7 fw-bolder">Welcome to Modernize</h2>
                                        <p className="mb-7">Your Admin Dashboard</p>
                                        <div className="row">
                                            <div className="col-6 mb-2 mb-sm-0">
                                                <a className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8"
                                                   href="javascript:void(0)" role="button">
                                                    <img src={`${PUBLIC_URL}/assets/images/svgs/google-icon.svg`} alt="" className="img-fluid me-2" width="18" height="18" />
                                                    <span className="flex-shrink-0">with Google</span>
                                                </a>
                                            </div>
                                            <div className="col-6">
                                                <a className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8"
                                                   href="javascript:void(0)" role="button">
                                                    <img src={`${PUBLIC_URL}/assets/images/svgs/facebook-icon.svg`} alt="" className="img-fluid me-2" width="18" height="18" />
                                                    <span className="flex-shrink-0">with FB</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="position-relative text-center my-4">
                                            <p className="mb-0 fs-4 px-3 d-inline-block bg-body text-dark z-index-5 position-relative">or
                                                sign
                                                in
                                                with</p>
                                            <span
                                                className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
                                        </div>
                                        <form>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                       className="form-label">Username</label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="exampleInputPassword1"
                                                       className="form-label">Password</label>
                                                <input type="password" className="form-control" id="exampleInputPassword1" />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                <div className="form-check">
                                                    <input className="form-check-input primary" type="checkbox" value=""
                                                           id="flexCheckChecked" checked />
                                                    <label className="form-check-label text-dark fs-3"
                                                           htmlFor="flexCheckChecked">
                                                        Remeber this Device
                                                    </label>
                                                </div>
                                                <a className="text-primary fw-medium fs-3"
                                                   href="../main/authentication-forgot-password.html">Forgot Password
                                                    ?</a>
                                            </div>
                                            <a href="../main/index.html"
                                               className="btn btn-primary w-100 py-8 mb-4 rounded-2">Sign In</a>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="fs-4 mb-0 fw-medium">New to Modernize?</p>
                                                <a className="text-primary fw-medium ms-2"
                                                   href="../main/authentication-register.html">Create an
                                                    account</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dark-transparent sidebartoggler"></div>

            <script src="./assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
            <script src="./assets/libs/simplebar/dist/simplebar.min.js"></script>
            <script src="./assets/js/theme/app.init.js"></script>
            <script src="./assets/js/theme/theme.js"></script>
            <script src="./assets/js/theme/app.min.js"></script>
            <script src="./assets/js/theme/sidebarmenu.js"></script>

            <script src="https://cdn.jsdelivr.net/npm/iconify-icon@1.0.8/dist/iconify-icon.min.js"></script>
        </>
    )
}

export default Login
