import React, {useEffect, useState} from 'react';

import BasicCRUD from './../core/basic-crud'
import {Link} from "react-router-dom";
import {PUBLIC_URL} from "../../const";
import {socketUseEffect} from "../../socket";
import Answers from "./answers"
import PopUpDialog from "../core/popup-dialog";
import TextParserComponent from "./test-parser-component";

const Tasks = (props) => {
    const object = "tasks"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    const [data, setData] = useState([]);
    const [lessonsData, setLessonData] = useState([]);
    const [orderNum, setOrderNum] = useState([]);

    const listeners = [
        {name: "sections", fn: "related", args: {...props.filters, status: 2}, saveDataFN: setData},
        {name: "tasks", fn: "lessons", args: {...props.filters, status: 2}, saveDataFN: setLessonData},
    ]

    useEffect(() => socketUseEffect(listeners), []);

    const DrawName = (data) => {
        return (
            <>
                Task # {data['id']}
            </>
        )
    }

    const DrawSatus = (data) => {
        const status = data['status'] == 1 ? "Published" : "Draft"

        return (
            <>
                {status}
            </>
        )
    }

    const columns = [
        {label:"Order", key: "list_num", width: "80px"},
        {label:"Name", key: "name", format: DrawName,},
        {label:"Status", key: "status", width: "100px", format: DrawSatus,},
        {label:"Created", key: "created_at", width: "200px"},
        {label:"Last Update", key: "updated_at", width: "200px"},
    ]

    const [showSurveyDld, setShowSurveyDld] = useState(false);
    const Parser = (props) => {
        return (
            <>
                <a href="javascript:void(0)" id="btn-add-contact"
                   className="btn btn-primary d-flex align-items-center" onClick={() => {setShowSurveyDld(true)}}
                   style={{marginTop: "5px", marginBottom: "15px", maxHeight: "40px", maxWidth: "150px",}}>
                    <i className="ti ti-users text-white me-1 fs-5"></i> Parse Survey
                </a>
                <PopUpDialog title={`Parse Survey`} show={showSurveyDld} hideDialog={setShowSurveyDld}>
                    <TextParserComponent data={fieldsData} setFieldsData={setFieldsData}/>
                </PopUpDialog>
            </>
        )
    }

    const dynamicFields = [
        [{label: "Order", key: "list_num", default_value: orderNum, showLabel: "top"}],
        [{label: "Status", key: "status", default_value: "1", type: "selectbox", data:[
                {id: "1", name: "Draft"},
                {id: "2", name: "Published"},
            ], showLabel: "top"}],
        [{label: "Type", key: "task_type", default_value: "", type: "selectbox", data:[
                {id: "1", name: "Code"},
                {id: "2", name: "Survey"},
                {id: "3", name: "Lesson"}
            ], showLabel: "top"}],
        [{label: "Auto Parser", key: "parser",  type: "custom", fn: Parser}],
        [{label: "Related Lessons", key: "related_lesson_id", default_value: "", type: "selectbox", data: lessonsData, showLabel: "top"}],
        [{label: "Related Module", key: "related_section_id", default_value: "", type: "selectbox", data: data, showLabel: "top"}],
        [{label: "Subject", key: "subject", default_value: "", showLabel: "top",}],
        [{label: "Task", key: "task", default_value: "", type: "draftjs", showLabel: "top",}],
        [{label: "Answer 1", key: "answer1", default_value: "", type: "draftjs", showLabel: "top",}],
        [{label: "Answer 2", key: "answer2", default_value: "", type: "draftjs", showLabel: "top",}],
        [{label: "Answer 3", key: "answer3", default_value: "", type: "draftjs", showLabel: "top",}],
        [{label: "Answer 4", key: "answer4", default_value: "", type: "draftjs", showLabel: "top",}],
        [{label: "Correct Answer", key: "correct_answer", default_value: "", type: "selectbox", data:[
                {id: "1", name: "Answer 1"},
                {id: "2", name: "Answer 2"},
                {id: "3", name: "Answer 3"},
                {id: "4", name: "Answer 4"}
            ], showLabel: "top"}],
        [{label: "Help", key: "clue", default_value: "", type: "draftjs", showLabel: "top",}],
        [{label: "Validate Solution", key: "solution", default_value: "", type: "textarea", showLabel: "top"}],
        [{label: "Answers", key: "answers", default_value: "", type: "custom", showLabel: "top", fn: Answers}],
    ]


    const showRule = (data, key) => {
        if (key == "subject"){
            if(data['task_type'] != "3"){
                return false
            }
        }

        if (key == "solution"){
            if(data['task_type'] != "1"){
                return false
            }
        }

        if (key == "answers" || key == "answer1" || key == "answer2" || key == "answer3" || key == "answer4" || key == "correct_answer"){
            if(!data['id'] || data['task_type'] != "2"){
                return false
            }
        }

        if (key == "answers"){
            return false
        }

        if (key == "related_section_id"){
            if(props.filters.is_exam != "1"){
                return false
            }
        }

        if (key == "clue"){
            if(data['related_lesson_id'] > 0){
                return false
            }
        }

        if(key == "parser"){
            if(!data['id'] || data['task_type'] != "2"){
                return false
            }
        }

        return true
    }

    const fields = [
        {
            type: "tab",
            name: "",
            image: "",
            showRule: showRule,
            fields: [...dynamicFields]
        },

    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [

    ]

    return (
        <>
            <BasicCRUD
                hideTitle={true}
                hideGroupActions={true}
                disableSaveFilters={true}
                args={{...props.filters, status: 2}}

                title={"Tasks"}
                subTitle={"add / edit tasks"}
                homeURL={"/dashboard"}
                addTitle="Add Task" editTitle={"Edit Task"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                noCreateDialog={true}
                multiActions={multiActions}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Task</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Task</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Task</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  Tasks;
