import React, {useEffect, useState} from 'react';

import BasicCRUD from './../core/basic-crud'
import {Link} from "react-router-dom";
import {PUBLIC_URL} from "../../const";
import {socketUseEffect} from "../../socket";

const Answers = (props) => {
    const object = "answers"
    const [response, setResponse] = useState({});
    const [fieldsData, setFieldsData] = useState({})

    console.log(" - params - ", props.data)

    const DrawName = (data) => {
        return (
            <>
                Answer # {data['id']}
            </>
        )
    }

    const DrawStatus = (data) => {
        let status = "No"
        if(data['is_correct'] == "1"){
            status = "Yes"
        }
        return (
            <>
                {status}
            </>
        )
    }


    const columns = [
        {label:"Order", key: "order_num", width: "80px"},
        {label:"Name", key: "name", format: DrawName,},
        {label:"Is Correct", key: "is_correct",  format: DrawStatus, width: "200px"},
    ]

    const fields = [
        {
            type: "tab",
            name: "",
            image: "",
            fields: [
                [{label: "Order", key: "order_num", default_value: "", showLabel: "top"}],
                [{label: "Is Correct", key: "is_correct", default_value: "", type: "selectbox", data:[{id: "0", name: "No"}, {id: "1", name: "Yes"}], showLabel: "top"}],
                [{label: "Answer", key: "description", default_value: "", type: "draftjs", showLabel: "top",}],
            ]
        },

    ]

    const actions = [
        {name: "edit", label:"Edit", controller: "crud"},
        {name: "delete", label:"Delete", controller: "crud"},
    ]

    const multiActions = [

    ]

    let filters = {task_id: props.data.id}
    return (
        <>
            <BasicCRUD
                hideTitle={true}
                hideGroupActions={true}
                disableSaveFilters={true}
                args={filters || {}}
                filters={filters || {}}

                title={"Answers"}
                subTitle={"add / edit answer"}
                homeURL={"/dashboard"}
                addTitle="Add Answer" editTitle={"Edit answer"}
                object={object} columns={columns} fields={fields} actions={actions}
                fieldsData={fieldsData} setFieldsData={setFieldsData}
                response={response} setResponse={setResponse}
                ownHandler={true}
                multiActions={multiActions}>

                {response.status && response.status === 1 ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            {response.created && response.created === 1 ? (
                                <>
                                    <strong>Answer</strong> created successfully
                                </>
                            ): null}
                            {response.updated && response.updated === 1 ? (
                                <>
                                    <strong>Answer</strong> updated successfully
                                </>
                            ): null}
                            {response.delete && response.delete === 1 ? (
                                <>
                                    <strong>Answer</strong> deleted successfully
                                </>
                            ): null}
                        </div>

                    </>

                ) : null}

            </BasicCRUD>
        </>
    )
}
export  default  Answers;
